<template>
    <div class="page" id="reorganize">
        <el-row :gutter="10">
          <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
            <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
                 @click="showList = !showList"></div>
                <div :class="['bg-white','leftHeighe']" v-show="showList">
                    <el-descriptions title="藏品分类"></el-descriptions>
                    <div class="flex_b_c">
                        <el-input size="small" placeholder="输入关键字进行过滤" clearable v-model="filterText"
                                  @input="filterTreeData"></el-input>
                    </div>
                    <el-tree v-loading="treeLoading" node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                             show-checkbox :filter-node-method="filterNode"
                             :props="defaultProps" @check="selRecord">
                        <div slot-scope="{ node, data }">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div class="omit">{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-col>
          <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
                <div class="text_center noData bg-white" v-if="!archivesBasicDataId.length">请选择左侧藏品分类</div>
                <div v-else>
                    <div class="bg-white">
                        <el-form class="query-form dynamicForm tabsCls2" size="small" ref="inputForm"
                                 :model="inputForm" @keyup.enter.native="getDataList(1,1,1)"
                                 :rules="rules" label-width="150px">
                            <el-form-item label="藏品名称" prop="collectionName">
                                <el-input v-model.trim="inputForm.collectionName"
                                          placeholder="请输入藏品名称(限50字)"
                                          maxlength="50" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="藏品编号" prop="helpNum">
                                <el-input v-model.trim="inputForm.helpNum"
                                          placeholder="请输入藏品编号(限50字)"
                                          maxlength="50"
                                          clearable>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="复刻藏品名称" prop="copyName">
                                <el-input v-model.trim="inputForm.copyName"
                                          placeholder="请输入复刻藏品名称(限50字)"
                                          maxlength="50" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="复仿制日期" prop="copyDate">
                                <el-date-picker
                                        style="width: 100%"
                                        clearable
                                        value-format="yyyy-MM-dd"
                                        v-model="inputForm.copyDate"
                                        type="date"
                                        placeholder="选择复仿制日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                        <div class="flex_b_c">
                            <div>
                                <el-button size="small" type="primary" @click="getDataList(1,1,1)"
                                           icon="el-icon-search">
                                    查询
                                </el-button>
                                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
                                </el-button>
                            </div>
                            <div>
                                <el-button type="primary"
                                           size="small"
                                           v-if="selectTab == 0 || selectTab == 99"
                                           v-show="hasPermissionButton(`collection:business:${typePage}:add`)"
                                           icon='el-icon-plus'
                                           @click="addData(0)">新增
                                </el-button>
                                <el-button type="primary"
                                           size="small"
                                           icon="el-icon-circle-check"
                                           v-show="hasPermissionButton(`collection:business:${typePage}:batchThrough`)"
                                           v-if="typePage != 'collectionEntry'"
                                           @click="through(1,'3',)">批量通过
                                </el-button>
                                <el-button type="danger"
                                           size="small"
                                           icon="el-icon-circle-close"
                                           v-show="hasPermissionButton(`collection:business:${typePage}:batchReject`)"
                                           @click="through(1,'2',)">批量驳回
                                </el-button>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white magTop">
                        <div class="flex_b_c">
                            <div class="tabsCls" v-show="typePage != 'productsExamine'">
                                <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                                    <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                                                 :name="item.value"></el-tab-pane>
                                </el-tabs>
                            </div>
                            <div></div>
                            <div>
                                <el-button icon="el-icon-upload2" size="small" v-if="selectTab == 0 || selectTab == 99"
                                           v-show="hasPermissionButton(`collection:business:${typePage}:import`)"
                                           @click="importDile()">
                                    批量导入
                                </el-button>
                                <el-button size="small" v-if="selectTab == 0 || selectTab == 99"
                                           v-show="hasPermissionButton(`collection:business:${typePage}:template`)"
                                           @click="templateDownload()">
                                    <i class="icon-daorumubanxiazai iconfont buIcon"/>
                                    导入模板下载
                                </el-button>
                                <el-button size="small" v-if="selectTab == 0 || selectTab == 99"
                                           v-show="hasPermissionButton(`collection:business:${typePage}:batchExpor`)"
                                           @click="exportData(1)">
                                    <i class="icon-piliangdaochu iconfont buIcon"/>
                                    批量导出
                                </el-button>
                            </div>
                        </div>
                        <el-table
                                :data="dataList"
                                size="small"
                                v-loading="loading"
                                ref="multipleTable"
                                height="calc(100vh - 350px)"
                                class="table"
                                :header-cell-style="{background:'#F9DFDF'}"
                                :row-key="'id'"
                                @selection-change="selectionChangeHandle"
                        >
                            <el-table-column :reserve-selection="true" type="selection" width="50"/>
                            <el-table-column label="序号" type="index" width="50"></el-table-column>
                            <el-table-column prop="processState" label="审核状态" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ $dictUtils.getDictLabel("process_state", scope.row.processState, '-') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="图标" width="120px">
                                <template slot-scope="scope">
                                    <div class="flex_a_c">
                                        <div v-if="scope.row.carded == 0"
                                             class="icon-wenwukapian iconfont listIcon"></div>
                                        <el-image
                                                style="width: 36px; height: 36px"
                                                :src="scope.row.picMasterViewUrl"
                                                :fit="'cover'"
                                                :preview-src-list="[scope.row.picMasterViewUrl]"
                                        >
                                            <div slot="error" class="image-slot">
                                                <el-image
                                                        style="width: 36px; height: 36px"
                                                        :src="require('@/assets/img/default.png')"
                                                        :fit="'cover'">
                                                </el-image>
                                            </div>
                                        </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="collectionName" label="藏品名称" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="copyName" label="复刻藏品名称" width="100px"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="copyType" label="复仿制类型" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ $dictUtils.getDictLabel("copy_type", scope.row.copyType, '-') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="copyDate" label="复仿制日期" width="150px">
                                <template slot-scope="scope">
                                    {{ scope.row.copyDate | formatDate('YYYY-MM-DD') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createUserName" label="创建者"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createDate" label="创建时间" width="150px">
                                <template slot-scope="scope">
                                    {{ scope.row.createDate | formatDate }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="updateUserName" label="最后更新人"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="updateDate" label="最后更新时间" width="150px"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ scope.row.updateDate | formatDate }}
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="180">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text"
                                               @click="addData(1, scope.row,scope.$index)"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                                        详情
                                    </el-button>
                                    <el-button size="mini" type="text"
                                               @click="submitTo(2,scope.row)"
                                               v-if="scope.row.processState == 0"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:submit`)">
                                        提交
                                    </el-button>
                                    <el-button size="mini" type="text"
                                               @click="submitTo(2,scope.row)"
                                               v-if="scope.row.processState == 2"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:resubmit`)">
                                        重新提交
                                    </el-button>
                                    <el-button size="mini" type="text"
                                               @click="addData(4, scope.row,scope.$index)"
                                               v-if="scope.row.processState == 0 || scope.row.processState == 2"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:edit`)">修改
                                    </el-button>
                                    <el-button size="mini" type="text"
                                               @click="record(scope.row)"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
                                        审核记录
                                    </el-button>
                                    <el-button size="mini" type="text"
                                               @click="deleteDate(scope.row)"
                                               v-if="scope.row.processState != 1"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">
                                        删除
                                    </el-button>
                                    <el-button size="mini" type="text"
                                               @click="through(2,3,scope.row)"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:adopt`)">
                                        通过
                                    </el-button>
                                    <el-button size="mini" type="text"
                                               @click="through(2,2,scope.row)"
                                               v-show="hasPermissionButton(`collection:business:${typePage}:reject`)">
                                        驳回
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="text_center">
                            <el-pagination
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageNo"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    :total="total"
                                    background
                                    layout="total, sizes, prev, pager, next, jumper"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <record ref="record"></record>
        <temDownload ref="temDownload"></temDownload>
        <reviewSuggestions ref="suggestions" @updataStatus="getDataList('',1)"></reviewSuggestions>
        <ExportData ref="exportData" @downLoad="getDataList('',1)"></ExportData>
        <ImportColl ref="importColl" @importFile="getDataList(1,1,1)"></ImportColl>
    </div>
</template>

<script>
import {hasPermissionButton} from "@/utils";
import record from "@/views/modules/collection/accounts/module/record.vue";
import temDownload from "@/views/modules/collection/accounts/module/temDownload.vue";
import ExportData from "@/views/modules/record/collect/exportData.vue";
import ImportColl from "@/views/modules/collection/accounts/module/importColl.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
    name: "productsManage",
    components: {reviewSuggestions, record, temDownload, ExportData, ImportColl},
    props: {
        typePage: {
            type: String,
                default: 'productsManage',
        },
    },
    data() {
        return {
            treeData: [],
            treeLoading: false,
            filterText: '',
            defaultProps: {
                children: 'children',
                label: 'archivesBasicName'
            },
          showList:true,

            selectTab: '99',
            tabsList: [
                {
                    name: '全部',
                    value: '99',
                },
                {
                    name: '审核中',
                    value: '1',
                },
                {
                    name: '审核驳回',
                    value: '2',
                },
                {
                    name: '草稿',
                    value: '0',
                },
            ],
            config: [],
            inputForm: {
                collectionName: '',
                helpNum: '',
                copyName: '',
                copyDate: '',
            },
            rules: {},
            archivesBasicDataId: [],
            loading: false,
            dataList: [],

            dataListSelect: [],
            pageNo: 1,
            pageSize: 10,
            total: 0,
            allTotal: 0,
            rejectagreeCount: 0,
            underReviewCount: 0,
            draftCount: 0,
            searchForm: {
                sortArchivesDataElasticsearch: [],
                whereList: [],
            },
            retract: true,
            searchRecord: {},
        }
    },

    mounted() {
        let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
        if (listSearch) {
            this.selectTab = listSearch.selectTab
            this.archivesBasicDataId = listSearch.archivesBasicDataId
            this.inputForm = listSearch.inputForm
            this.pageNo = listSearch.pageNo
            this.pageSize = listSearch.pageSize
            this.searchTree(1)
            sessionStorage.removeItem('listSearch')
        } else {
            this.searchTree()
        }
    },

    methods: {
        hasPermissionButton,

        // 查询树状数据
        searchTree(type) {
            this.treeData = [
                {
                    id: '',
                    archivesBasicName: '全部',
                    children: [],
                },
            ]
            this.treeLoading = true
            this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
                if (res.status) {
                    this.treeData[0].children = res.data
                    //全选
                    this.$nextTick(() => {
                        if (!type) {
                            this.getTreeId(this.treeData)
                        }
                        this.$refs.tree.setCheckedKeys(this.archivesBasicDataId);
                        this.selRecord('', '', type)
                    })
                }
                this.treeLoading = false
            })
        },

        //循环取树形控件id
        getTreeId(data) {
            if (data && data.length != 0) {
                data.forEach(item => {
                    this.archivesBasicDataId.push(item.id)
                    if (item.children) {
                        this.getTreeId(item.children)
                    }
                })
            } else {
                return
            }
        },

        // 选择档案分类
        selRecord(data, checked, type) {
            //该节点所对应的对象、树目前的选中状态对象
            this.searchForm.sortArchivesDataElasticsearch = []
            if (checked) {
                this.archivesBasicDataId = []
                this.archivesBasicDataId = checked.checkedNodes.map(item => {
                    return item.id
                })
            }
            if (this.archivesBasicDataId.length == 0) return
            let getByIdUrl = this.api.collection.basicdataGetById + '/' + this.archivesBasicDataId
            if (this.archivesBasicDataId.length > 1) {
                getByIdUrl = this.api.collection.getArchivesBasicByRequired
            }
            this.$axios(getByIdUrl).then(data => {
                if (data.status) {
                    if (!type) {
                        this.inputForm = {
                            helpNum: '',
                            collectionName: '',
                            integrity:'',
                            integrityInfo:'',
                        }
                    }
                    this.rules = {}
                    this.config = data.data.archivesBasicDataGroup
                    this.config.map(item => {
                        if (item.dataWhere == 0) {
                            this.$set(item, 'value', '')
                            this.$set(this.inputForm, item.code, '')
                            if (item.archivesBasicTypeCode == 'integer') {
                                this.$set(this.rules, item.code, [{
                                    validator: this.validator.isDigits,
                                    trigger: 'blur'
                                }])
                            }
                        }
                    })
                    this.getDataList(type ? '' : 1, 1, 1)
                }
            })
        },

        //获取列表数据
        getDataList(type, dividePage) {
            this.$nextTick(()=>{
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        if (type == 1) {
                            this.pageNo = 1
                        }
                        if (dividePage == 1) {
                            this.$refs.multipleTable.clearSelection()
                        }
                        let archivesBasicDataId = this.archivesBasicDataId.filter(item => {
                            return item == ''
                        })
                        if (archivesBasicDataId.length != 0) {
                            archivesBasicDataId = null
                        } else {
                            archivesBasicDataId = this.archivesBasicDataId
                        }
                        let selectTab = this.selectTab
                        if (this.selectTab == 99) {
                            selectTab = ''
                        }
                        this.searchRecord = {
                            ...this.inputForm,
                            current: this.pageNo,
                            size: this.pageSize,
                            processState: selectTab,
                            archivesBasicDataId: archivesBasicDataId,
                        }
                        let fetchUrl = this.api.collection.collectiondatacopyList
                        if(this.typePage == 'productsExamine'){
                            fetchUrl = this.api.collection.listRevd
                        }
                        this.$axios(fetchUrl, this.searchRecord, 'post').then((res) => {
                            if (res.status) {
                                this.dataList = res.data.records
                                this.total = parseInt(res.data.total)
                                if (this.dataList.length == 0 && this.pageNo > 1) {
                                    this.pageNo--
                                    this.getDataList()
                                }
                                this.$nextTick(() => {
                                    this.$refs.multipleTable.doLayout()
                                })
                            } else {
                                this.$message.error('查询失败');
                            }
                            this.loading = false
                        })
                    }
                })
            })
        },

        //导入
        importDile() {
            this.$refs.importColl.init(this.treeData[0].children,'','',{
              type: 2,
            })
        },

        //筛选树的数据
        filterTreeData() {
            this.$refs.tree.filter(this.filterText);
        },

        filterNode(value, data) {
            if (!value) return true;
            return data.archivesBasicName.indexOf(value) !== -1;
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1, 1)
        },

        //导出 type 1批量导出 2全部导出
        exportData(type) {
            let ids = []
            if (this.dataListSelect.length == 0) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            ids = this.dataListSelect.map(item => {
                if (item) {
                    return {
                        id: item.id,
                        archivesBasicDataId: item.archivesBasicDataId
                    }
                }
            })
            this.$refs.exportData.init(ids, this.config, this.archivesBasicDataId, '藏品数据', 1)
        },

        //提交 type 1批量提交 2单个提交
        submitTo(type, row) {
            let ids
            if (type == 1) {
                if (this.dataListSelect.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                ids = this.dataListSelect.map(item => {
                    if (item) {
                        return item.id
                    }
                })
            } else {
                ids = [row.id]
            }
            this.$confirm(`您是否确认提交数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.collection.bacthSubmit, {
                    ids: ids,
                    revdId: 0,
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.$message.success('提交成功')
                        this.getDataList('', 1)
                    }
                })
            })
        },

        //导入模板下载
        templateDownload() {
            // this.$refs.temDownload.init(this.treeData[0].children)
          this.$axios(this.api.collection.exportCopyModel, {}, 'get', 'blob').then(res => {
            const blob = new Blob([res.data], {
              // 下载的文件格式自己在这边更改        type的值就好了
              type: 'application/vnd.ms-excel'
            })
            let filename = '复仿制品导入模板';
            let link = document.createElement('a')
            link.download = decodeURI(filename)
            link.href = window.URL.createObjectURL(blob)
            document.body.appendChild(link);
            link.click()
            URL.revokeObjectURL(link.href);  //释放url
            document.body.removeChild(link);  //释放标签
          })
        },

        // 0新增 1详情 2修改 4重新提交
        addData(num, row, index) {
            if (num != 0) {
                let listSearch = {
                    selectTab: this.selectTab,
                    archivesBasicDataId: this.archivesBasicDataId,
                    inputForm: this.inputForm,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                }
                sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
                this.$set(this.searchRecord, 'viewCurrent', (this.pageNo - 1) * this.pageSize + index + 1)
                sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
            }
            if (num == 0) {
                this.$router.push({
                    path: '/collection/business/products/addProducts',
                    query: {butType: num},
                })
            }else {
                if(this.typePage == 'productsExamine'){
                    num = 3
                }
                this.$router.push({
                    path: '/collection/business/products/addProducts',
                    query: {butType: num, id: row.id, archivesBasicDataId: row.archivesBasicDataId,typePage:this.typePage}
                })
            }
        },

        //num 1多个 2单个  type 2驳回 3通过
        through(num, type, row) {
            let setData = []
            let revdId = 16
            if (num == 1) {
                if (this.dataListSelect.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                setData = this.dataListSelect
            } else {
                setData = [row]
            }
            this.$refs.suggestions.init(num, setData, type, 0)
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.id,'',17)
        },

        //删除数据
        deleteDate(row) {
            this.$confirm(`您是否确认删除数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.collection.removeById, {
                    id: row.id,
                    archivesBasicDataId: row.archivesBasicDataId,
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        handleClick(tab, event) {
            this.getDataList(1, 1, 1)
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', 2);
        },
    },
}
</script>

<style lang="scss" scoped>
.leftHeighe {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.treeDateCls {
  overflow-y: auto !important;
  height: calc(100% - 80px);
}

    .magTop {
        margin-top: 10px;
    }

.tabsCls2 {
    margin: 15px 0 0;
}

.searchBotton ::v-deep .el-form-item__content {
    /*margin-bottom: 0 !important;*/
}

.noData {
    line-height: calc(100vh - 154px);
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}

.table {
    &::v-deep .el-table {
        .el-table__fixed {
            height: auto !important;
            bottom: 10px !important;
        }
    }

    &::v-deep .el-table__body-wrapper {
        z-index: 2
    }
}

.buIcon {
    font-size: 12px;
    margin-right: 5px;
}

.table {
    .el-table__fixed-right,
    .el-table__fixed {
        height: auto !important;
        bottom: 17px !important;
    }
}
</style>