<template>
  <div>
    <el-upload v-show="false" class="i_block m_r1 m_l1"
               ref="upload"
               :disabled="uploadAskObj.disabled"
               action="action" :show-file-list="false"
               :http-request="getImportData"
               :before-upload="beforeUpload">
    </el-upload>
    <el-dialog
        title="批量导入"
        :visible.sync="dialogVisible"
        width="600px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose">
      <div>
        <div v-loading="importLoading" element-loading-text="数据导入中"
             element-loading-background="rgba(255, 255, 255, 1)">
          <el-result icon="success" title="导入成功" :subTitle="importData.data.msg"
                     v-if="importData.status"></el-result>
          <el-result icon="err or" title="导入失败" v-else></el-result>
          <div v-if="!importLoading && !importData.status" class="errDateMsg">
            <!--            <div>{{ importData.msg }}</div>-->
            <div v-for="(item, index) in importData.msg" :key="index">{{ item }}</div>
            <div></div>
          </div>
        </div>
        <div v-if="!importLoading" slot="footer" class="text_center">
          <el-button size="small" @click="handleClose()">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from "@/components/Upload/Upload.vue";

export default {
  name: "importColl",
  components: {Upload},
  data() {
    return {
      dialogVisible: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'archivesBasicName'
      },
      uploadAskObj: {
        list: [],
        btnName: '导入藏品',
        fileType: ['xlsx', 'xls'],
        disabled: false,
        attachedParameters: {
          archivesBasicDataId: '',
        },
        uploadUrl: 'zyd-collection/archivesdataoperation/excelImport',
      },

      feachUrl: '',
      diaLogType: 1,
      importLoading: true, //文件状态
      importData: {}, //文件信息
      count: 0,
      upData: {},
    }
  },

  methods: {
    init(data, url, count, upData) {
      this.diaLogType = 1
      this.treeData = data
      if (url) {
        this.feachUrl = url
      } else {
        this.feachUrl = this.api.collection.excelImport
      }
      if (count) {
        this.count = count
      }
      if (upData) {
        this.upData = upData
      }
      this.importLoading = true; //文件状态
      this.importData = {};
      // this.dialogVisible = true
      // this.$nextTick(()=>{
      this.$refs.upload.$refs['upload-inner'].handleClick()

      // })
    },

    // selRecord(selectData) {
    //     this.uploadAskObj.disabled = false
    //     this.uploadAskObj.attachedParameters.archivesBasicDataId = selectData.id
    // },
    //
    handleClose() {
      // this.uploadAskObj.disabled = true
      this.dialogVisible = false
    },

    beforeUpload(file) { // 上传文件之前钩子
      const type = file.name.split('.')[1]
      if (type !== 'xls' && type !== 'xlsx') {
        this.$message({type: 'error', message: '只支持xls和xlsx文件格式！'})
        return false
      }
      this.diaLogType = 2
    },

    // 导入
    getImportData(param) {
      this.dialogVisible = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('count', this.count)
      Object.keys(this.upData).forEach(key => {
        formData.append(key, this.upData[key])
      })
      this.$axios(this.feachUrl, formData, 'post').then(data => {
        if (data.status) {
          this.importData = data
          console.log(this.importData)
          this.importLoading = false
          if (data.data.code) {
            this.$emit('importFile', data.data.list)
          }
        } else {
          this.importData = data
          this.importData.msg = JSON.parse(this.importData.msg)
          this.importLoading = false
          // this.handleClose()
          // this.$message.error(data.msg)
        }
      })
    },
  }
}
</script>

<style scoped>
.errDateMsg {
  padding: 15px;
  background: #e3f7eb;
  margin-bottom: 10px;
  max-height: 220px;
  overflow-y: auto;
}
</style>